class ZIDXSignIn implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxSignInContainer";
    }
    getMatchURL(){
        return "/sign-in";
    }
    render(){
        // console.log("sign in");
    }
}